$color-light-yellow: #efd798;
$color-primary-blue: #256D80;
$color-secondary-blue: #2B7D93;
$color-secondary-light-blue: #5BA5B6;
$color-secondary-dark-blue: #144a59;

 .main-container {
    h1{
        small{
            font-size: 1rem;
        }
    }
}
.user-container {
    h1 {
        small {
            font-size: .7em;
        }
    }
}

.btn-add {
    height: 100%;
    padding-top: 3rem;
    padding-bottom: 3rem;
    border: 4px solid lighten($noir, 90%);
    font-size: 1.5rem;
    font-weight: 300;
    color: lighten($noir, 60%);
    transition: all .2s;
    &:hover {
        cursor: pointer;
        background-color: lighten($noir, 95%);
    }
}
.sortable-actif {
  color: #000;
}
.sortable-disabled {
  color: #e6e6e6;
}
.popup-overlay {
visibility:hidden;
}

.popup-content {
visibility:hidden;
}

.popup-overlay.active{
visibility:visible;
}

.popup-content.active {
visibility:visible;
}

#tax_bands_container,
#tax_status_container{
  display: none;
}

.nav-user-show{
  margin: 0 !important;
  background-color: $color-primary-blue;
  a{
    padding: 1.5rem;
    font-weight: bold;
    color: white;
    border-bottom: solid 1px !important;
    text-align: left;
  }
  a:hover{
    color:white;
    border: none;
    background-color: $color-secondary-light-blue;
  }
  a.active{
    margin: 0;
    color: #48C0DD !important;
    background-color: $color-primary-blue !important;
    border: none !important;
    border-bottom: solid 1px white !important;
  }
}

.row-no-margin-right{
  margin-right: 0;
}

.front-body-container{
  max-width: 1400px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.container-user{
  padding: 0;
  margin: 0 !important;
  background-color: $color-secondary-blue;
  margin: 0 !important;
  color: white;
  th{
    background-color: white !important;
    color: $color-secondary-blue;
  }
  tr{
    i{
      color: $color-secondary-blue !important;
    }
    a{
      font-style: bold;
      color: $color-secondary-blue;
    }
  }
  tr:nth-child(even) {
    span, div{
      background-color: #f5f5f5;
    }
    background-color: #f5f5f5;
    color:$color-secondary-blue !important;
  }
  tr:nth-child(odd) {
    span, div{
      background-color: white;
    }
    background-color: white;
    color:$color-secondary-blue;
  }
  tr:nth-child(even):hover {
    background: #f5f5f5;
    color:$color-secondary-blue !important;
  }
  tr:nth-child(odd):hover {
    tr span{
      background-color: white;
    }
    background: white;
    color:$color-secondary-blue;
  }
  a{
    font-weight: bold;
    color: white;
  }
  a.btn-outline-danger {
    color: red!important;
    &:hover {
      color: white !important;
    }
  }
  i {
    color: white !important;
  }
  i.fa-trash-alt {
    color: currentColor!important;
  }
  h1{
    color:white !important;
    border-bottom: 4px solid white !important;
  }
  span{
    color:white;
  }
  div {
        background-color: $color-secondary-blue;
  }
  #toast-id, .toast-header, .toast-body{
    background-color: white!important;
  }
  .lead {
    font-size: 1.7rem;
    font-weight: 400;
  }
  .btn-sm {
    font-size: 1.2rem;
    font-weight: 400;
  }
  .pagination .current, .pagination span a{
      border: 1px solid white!important;
      color: white!important;
  }
  .container{
    max-width: 100%;
  }
  .card{
    border: none !important;
    a, i, h1, span{
      color: $color-secondary-blue !important;
    }
  }
  .card-input-header, .card-header{
    border: none;
    border-bottom: solid $color-secondary-blue;
    text-align: center;
    font-size: 1.25rem;
    p{
      font-size: 1.25rem;
      border: none;
      border-bottom: solid $color-secondary-blue;
      text-align: center;
    }
  }
  .card-header{
    border-bottom: none;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .tax-panel-header{
    border: none !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .input-group{
    a{
      i{
        background-color: $color-secondary-blue !important;
        color: white!important;
      }
    }
  }
  .button-help-tax, .btn-add-bank, .btn-add-green{
    color: $color-secondary-blue !important;
    background-color: #CBFF00!important;
    i{
      color: $color-secondary-blue!important;
    }
  }
  .btn-edit{
    font-weight: normal!important;
    background-color: white !important;
    color: orange !important;
    border: solid 1px orange;
    i{
      color: orange!important;
    }
  }
  .btn-edit:hover{
    border: solid 1px white;
    background-color: orange !important;
    color: white !important;
    i{
      color: white!important;
    }
  }
  //Documents
  .bloc_document{
    a:not(.btn-success){
      color: $color-primary-blue;
    }
    i{
      color: $color-secondary-blue !important;
    }
  }
  //Tax
  .tax-form-container{
    .card-footer{
      a{
         color: $color-secondary-light-blue;
      }
    }
  }
  //Beneficiaries
  #beneficiary-panel .card-body{
    box-shadow: 0 1rem 3rem rgba(0,0,0,.275)!important;
    padding: 1rem!important;
    background-color: white;
    border-radius:.0rem!important;
    div{
      background-color: white!important;
    }
    i{
      color:$color-secondary-blue!important;
    }
    span{
      color: $color-secondary-blue !important;
      background-color: white!important;
    }
    h5{
      color: $color-secondary-blue !important;
    }
    .btn-edit{
      font-weight: normal!important;
      background-color: white !important;
      color: orange !important;
      border: solid 1px orange;
      i{
        color: orange!important;
      }
    }
    .btn-edit:hover{
      border: solid 1px white;
      background-color: orange !important;
      color: white !important;
      i{
        color: white!important;
      }
    }
  }
  .card.border-bottom{
    box-shadow: 0 1rem 3rem rgba(0,0,0,.275)!important;
    padding: 1rem!important;
    background-color: white;
    border-radius:.0rem!important;

    .btn-remove{
      color: red;
    }
    .btn-remove:hover{
      color: white;
    }
    div{
      background-color: white;
      color: $color-secondary-light-blue;
    }
    span{
      color:$color-secondary-light-blue;
    }
    button {
      text-align: center;
      margin: 0 auto;
      display: block;
      background-color: white;
      color:$color-secondary-light-blue;
      border: solid 5px $color-secondary-light-blue;
    }
    button:hover{
      color: white;
      background-color: $color-secondary-light-blue;
    }
  }

}

input.choose_ad{
  display: none;
}
.row.beneficiaries{
  .card{
    height: 100%;
  }
}
.beneficiary-footer{
  .btn-merge-spouse,
  .btn-addrelation,
  .btn-choose{
    display: none;
  }
}
.beneficiary_choice{
  .card{
    cursor : pointer;
    transition: all 0.3s ease-in-out;
    &:hover{
      box-shadow: 0px 0px 14px -2px rgba(8 ,65 ,77, 70%);
      border-color: $color-primary-blue;
    }
    &.chosen{
      border-color: $color-primary-blue;
      .card-header,
      .card-footer span,
      .card-footer{
        display: block !important;
        color: $white;
        background-color: $color-primary-blue;
      }
    }
    .card-footer{
      display: none !important;
      input{
        margin-right : 5px;
      }
    }
  }
}

.message_ie{
  display: none;
}
