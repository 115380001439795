.card-info-cta{
  display: none;
}
.hight-light{
  background: #ffdf7e;
}
.table-header{
  background: #f7f7f7;
}
.tbody-change{
  .temp-first-row{
    border-top: #999 2px solid;
  }
}

@media screen and (min-width: 1600px) {
  .card-profil-apas-onf-panel {
    position: fixed;
    right: 20px;
    z-index: 100;
    width: 29%;
  }

  .card-profil-apas-onf-body {
    max-height: 400px;
    overflow: auto;
  }

  .card-change-apas-onf-panel {
    position: fixed;
    bottom: 14px;
    right: 20px;
    z-index: 100;
    width: 29%;
  }

  .card-change-apas-onf-body {
    height: 350px;
    overflow: auto;
  }

  .split-screen{
    width: 65%;
    margin-left: 0;
  }

  .card-info-cta{
    display: block;
  }
}

@media screen and (max-width: 1600px) {
  .hight-light .span-diff{
    position: absolute;
    top: -31px;
  }
}
