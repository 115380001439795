.main-sidebar {
    min-width: 250px;
    @media  screen and (max-width: $breakpoint_lg) {
        min-width: 50px;
    }
    a {
        color: lighten($noir, 30%);

        & > span:not(.badge){
            @media  screen and (max-width: $breakpoint_lg) {
                display: none;
            }
        }
    }
    .list-group-item {
        background-color: transparent;
        &:hover {
            background-color: lighten($noir, 85%);
        }
        &.active {
            border-color: lighten($noir, 30%);
            background-color: lighten($noir, 30%);
            color: $blanc;
        }
    }
    .list-group-flush {
        .list-group-item {
            &:last-child {
                border-bottom: 1px solid rgba(0,0,0,0.250) !important;
            }
        }
    }
}
.sidebar-header {
    background-color: lighten($noir, 90%);
}
.role-role_od .sidebar-header {
  background-color: transparent;
}
/* Users items */
.menu-collapse {
    margin-bottom: -1px;
    .collapse-btn {
        background-color: lighten($noir, 80%);
        &.collapsed:not(.active) {
            background-color: transparent;
        }
    }
}
.menu-list-group {
    background-color: lighten($noir, 90%);
    .list-group-item {
        &.active {
            background-color: transparent; // lighten($noir, 70%);
            border-color: rgba(0,0,0,.125);
            color: lighten($noir, 30%);
            font-weight: bold;
        }
    }
}
