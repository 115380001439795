.slick-next {
  left: 90%;
  height: 15% !important;
  width: 10% !important;
  opacity: 0.7 !important;
  transform: none !important;
  top: 30% !important;
}

.slick-prev {
  left: 0% !important;
  height: 15% !important;
  width: 10% !important;
  opacity: 0.7 !important;
  transform: rotate(180deg) !important;
  z-index: 10 !important;
  top: 30% !important;
}

.slick-img {
  width: 100% !important;
  height: 25em !important;
}
