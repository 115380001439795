html {
    font-size: 12px;
    @media screen and (min-width: $breakpoint_lg) {
        font-size: 14px;
    }
}

#moulinette-log{
    height: 200px;
    width: 100%;
    padding: 1rem;
    overflow-y: auto;
    border: #ccc 1px solid;
    scroll-behavior: smooth;
}