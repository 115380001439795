table.hide4holidayVoucher{
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  border: 0;
  border-radius: 3px;
  th{
    &.holiday_voucher_amount{
      border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
      font-weight: normal;
      font-size : 15px;
      font-size: 22px;
    }
  }
  td{
    &.holiday_voucher_amount{
      border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px) ;
      .amount_input_group{
        .input-group-text{
          padding: 1rem 0.75rem;
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 0.5rem;
        }
        input{
          padding: 1rem 0.75rem;
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 1.5rem;
        }
      }
    }
  }
  th,td{
    &:not(.holiday_voucher_amount){
      display: none;
    }
    &.holiday_voucher_amount{
      text-align: left !important;
      background-color: $primary;
      color: $white;
      border: 0;
    }
  }
}

.card-columns.beneficiary-options {
  column-count: 1;
  @include media-breakpoint-only(lg) {
    column-count: 2;
  }
  @include media-breakpoint-only(xl) {
    column-count: 2;
  }
}

.my-z-index-0{
  z-index: 0 !important;
}
.step_disabled{
  position: relative;
  opacity: 0.6;
  &::after{
    content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top : 0px;
      left: 0px;
  }
}
.table.subscription{
  .actions{
    a{
      span.text{
        display: none;
      }
      &:hover{
        text-decoration: none;
      }
    }
  }
}
body#subscription_show{
  .actions{
    margin-bottom: 15px;
    a.show{
      display: none;
    }
  }
}
