.content-nav {
    .nav-tabs {
        border-bottom: 1px solid $gris-25;
        .nav-link {
            margin-right: -1px;
            padding: 1rem 1.5rem;
            border-radius: 0;
            border-bottom: 0;
            border-top: 1px solid $gris-25;
            border-left: 1px solid $gris-25;
            border-right: 1px solid $gris-25;
            color: $gris-75;
            &.active {
                background-color: $gris-25;
                color: $blanc;
            }
            &:hover:not(.active) {
                background-color: $gris-10;
            }
        }
    }
  &.admin_user_tabs{
    display: flex;
    justify-content: space-between;
    border-bottom: 4px solid $primary;
    h1{
      border: 0;
      font-size: 2.2rem;
      font-weight: 500 !important;
      margin: 0;
      padding: 0;
      line-height: unset;
    }
    .nav-tabs {
      border-bottom: 0px;
      .nav-link {
        margin-right: 0px;
        border-top: 1px solid $primary;
        border-left: 1px solid $primary;
        border-right: 1px solid $primary;
        &.active {
            background-color: $primary;
            color: $blanc;
        }
        &:hover:not(.active) {
          background-color:$secondary;
          color: $blanc;
        }

      }
    }
  }
}

.navbar-brand{
    display: flex;
    align-items: center;
    .subtitle{
        font-size: 1rem;
        margin-top: 0.4em;
        margin-left: 0.3em;
    }
}

header.main-header.env-local .navbar,
header.main-header.env-Preprod .navbar,
header.main-header.env-Dev .navbar{
  background-color: #4c1515 !important;
  background: linear-gradient(55deg, rgba(8,65,77,1) 0%, rgba(8,65,77,1) 10%, rgba(190,22,22,1) 100%) !important;
}
