#container-pikaday .pika-single {
  zoom: 1.3;
  z-index: 0;
}

#container-pikaday .pika-lendar{
  width: 95%;
}

.is-disabled .pika-button{
  background: #e6000060;
  opacity: 1;
  color:#5a5555;
}
#container-pikaday .pika-day.no-hover:hover {
  color: #212529; 
}
.pika-day.pika-button:hover,
.pika-day.pika-button{
  border-radius: 0px;
  text-align: center;
}
.pika-day.endRangeDisabled{
  background: linear-gradient(to bottom right,  #e6000060 0%, #e6000060 50%, #f5f5f5 50%, #f5f5f5 100%) ;
  &:hover{
    background: linear-gradient(to bottom right,  #e6000060 0%, #e6000060 50%, #ff8000 50%, #ff8000 100%) ;
  }
}
.pika-day.startRangeDisabled{
  background: linear-gradient(to bottom right,  #f5f5f5 0%, #f5f5f5 50%, #e6000060 50%, #e6000060 100%) ;
  &:hover{
    background: linear-gradient(to bottom right,  #ff8000 0%, #ff8000 50%, #e6000060 50%, #e6000060 100%) ;
  }

}
.is-startrange{
  .pika-day{
    background: linear-gradient(to bottom right,  #f5f5f5 0%, #f5f5f5 50%, #6CB31D 50%, #6CB31D 100%) ;
    color: #000000;
    &.endRangeDisabled{
      background: linear-gradient(to bottom right,  #e6000060 0%, #e6000060 50%, #6CB31D 50%, #6CB31D 100%) ;
    }
  }
}
.is-endrange{
  .pika-day{
    background: linear-gradient(to bottom right,  #33aaff 0%, #33aaff 50%, #f5f5f5 50%, #f5f5f5 100%) ;
    color: #000000;
    font-weight: bold;
    &.startRangeDisabled{
      background: linear-gradient(to bottom right,  #33aaff 0%, #33aaff 50%, #e6000060 50%, #e6000060 100%) ;
    }
  }
}
.is-disabled .pika-day.endRangeDisabled,
.is-disabled .pika-day.startRangeDisabled,
.is-disabled .pika-day.startRangeDisabled.endRangeDisabled,
.pika-day.startRangeDisabled.endRangeDisabled{
  background: linear-gradient(to bottom right,  #e6000060 0%, #e6000060 50%, #e6000060 50%, #e6000060 100%) ;
}
#container-pikaday-end{
  z-index:1;
}

#container-pikaday-start{
  z-index: 1;
}

.scroll-erase-dates{
  overflow-y: auto;
  max-height: 15em
}

.custom-select-no-bg{
  background: none !important;
}
