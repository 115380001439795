.installement_wrapper{
  form.installement-search-form{
    input.pickadate{
      display: inline-block;
      width: auto;
    }
  }
  table.table.installement{

    tr{
      td{
        transition: all 0.3s ease-out;
      }
      &.disabled{
        background-color: #e6e6e6;
        td{
          opacity: 0.5;
          i{
            color: #4d4d4d !important;
            cursor: not-allowed;
          }
          a{
            color: #4d4d4d !important;
            cursor: not-allowed;
          }
        }
      }
      &.modified{
        td.amount,
        td.amountleft{
          animation-iteration-count: 1;
          -webkit-animation-name: flash; /* Safari 4.0 - 8.0 */
          -webkit-animation-duration: 3s; /* Safari 4.0 - 8.0 */
          animation-name: flash;
          animation-duration: 3s;
        }
      }
      &.modified_export{
        td.export{
          animation-iteration-count: 1;
          -webkit-animation-name: flash; /* Safari 4.0 - 8.0 */
          -webkit-animation-duration: 3s; /* Safari 4.0 - 8.0 */
          animation-name: flash;
          animation-duration: 3s;
        }
      }
    }
    td.credit{
      color : #28a745;
    }
    td.debit{
      color : #fd7e14;
    }
    td{
      .spinner-percent{
          display: none;
      }
      a{
        cursor: pointer;
        &:hover{
          filter: brightness(85%);
        }
      }
      &.form{
        a{
          i{
            font-size: 20px;
          }
        }
      }
    }
    td.dueDate{
      input{
        width: 100px;
        padding: 0.375rem 0.375rem;
      }
    }
    td.amount{
      input{
        max-width: 70px;
        text-align: center;
        padding: 0.375rem 0.375rem;
      }
    }
    td.payment_type,
    td.status{
      select{
        min-width: 100px;
        padding: 0.375rem 0.2rem;
      }
    }
  }
}

/* Safari 4.0 - 8.0 */
@-webkit-keyframes flash {
  0% {background-color: #b3e4be;font-weight: bold}
  50% {background-color: #b3e4be;font-weight: bold}
  100% {font-weight: normal}
}

/* Standard syntax */
@keyframes flash {
  0% {background-color: #b3e4be;font-weight: bold}
  50% {background-color: #b3e4be;font-weight: bold}
  100% {font-weight: normal}
}
