.select2-selection__rendered {
  line-height: 32px !important;
}
.select2-container .select2-selection--single {
  height: 36px !important;
}
.select2-selection__arrow {
  height: 35px !important;
}
.select2 {
    width: 100% !important;
}
