#login{

  background-image: url("../../public/img/login-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100vh;
}
.login-background{
  background-color: #f8f9fabb;
  width: 100%;
  max-width: 380px;
  margin-top: 5%;
  padding-top: 50px;
  padding-bottom: 50px;
  border-radius: 10px;
}
.login-form{
  img{
    max-width: 100%;
    display:block;
    margin: 0 auto;
  }
  max-width: 260px;
  width: 90%;
  margin: 0 auto;
}
