.email_progressBar{
  width : 100%;
  height : 20px;
  background-color: #eeeeee;
  > div{
    height : 20px;
    float: left;
    span{
      color:$white;
      display: none;
    }
  }
}
.email_progressBar_total{
  span{
    display: none;
  }
}

.statsPage .email_progressBar{
  height : 40px;
  > div{
    height : 40px;
    overflow: hidden;
    line-height: 40px;
    text-align: center;
    span{
      display: block;
    }
  }
}
.statsPage .email_progressBar_total{
  font-size: 20px;
  span{
    display: inline-block;
  }
}
