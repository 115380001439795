div.currentExport{
  clear: both;
  a{
    cursor: pointer;
    &:hover{
      filter: brightness(85%);
    }
  }
  .spinner-percent{
    display: none;

  }
}
div.actions_export{
  clear: both;
  a{
    cursor: pointer;
    &:hover{
      filter: brightness(85%);
    }
  }
  .spinner-percent{
    display: none;
  }
  table.table_actions{
    td{
      padding: 5px 10px;
      font-style: italic;
      &.td_action_results,&.td_action_checked{
        border-right: 1px solid #6c757d;
      }
      &.td_action_checked{
        border-top: 1px solid #6c757d;
        opacity: 0.5;
        &.active{
          opacity: 1;
        }
      }
    }
  }
}
.swal-button.swal-button--confirm.btn.btn-primary.swal-button--danger:hover{
  background-color: darken($dark, 10%);
}
