@import "variables";
@import "~bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import '../../node_modules/pikaday/css/pikaday.css';
@import '../../node_modules/select2/dist/css/select2.css';
@import '../../node_modules/jquery-wizard/dist/css/wizard.css';
@import '../../node_modules/slick-carousel/slick/slick-theme.css';
@import '../../node_modules/slick-carousel/slick/slick.css';
@import '../../node_modules/leaflet/dist/leaflet.css';
// Custom css
@import "_toast";
@import "fonts";
@import "common";
@import "_errors";
@import "back/back";
@import "sidebar";
@import "navs";
@import "content";
@import "address";
@import "category";
@import "user";
@import "temp";
@import "email-box";
@import "_timeline";
@import "_slider";
@import "_calendar";
@import "message";
@import "form";
@import "typehead";
@import "benefit";
@import "select2";
@import "installement";
@import "subscription";
@import "leaflet";
@import "images";
@import "export";
@import "email";
@import "front/pagination";
@import "front/_modal";
@import "login";
@import "_version";
