// Colors

$noir: #000;
$blanc: #fff;
$gris-5: lighten($noir, 95%);
$gris-10: lighten($noir, 90%);
$gris-25: lighten($noir, 75%);
$gris-50: lighten($noir, 50%);
$gris-75: lighten($noir, 25%);
$gris-90: lighten($noir, 10%);

$light: #f8f9fa;
$dark: #08414d;

$primary: $dark;
$secondary: #1e839a;
$tertiary: #cbff00;

// bootstrap
$blue:    $secondary;
// $indigo:  #6610f2 !default;
// $purple:  #6f42c1 !default;
// $pink:    #e83e8c !default;
// $red:     #dc3545 !default;
// $orange:  #fd7e14 !default;
// $yellow:  #ffc107 !default;
$green:   #97aa00 !default;
// $teal:    #20c997 !default;
$cyan:    #44c8e6 !default;

// Prestations
$aides-sociales: #c868a6;
$cheques-vacances: #5fdabe;
$maisons-forestieres: #47864c;
$voyages: #3d66db;
$activites-nationales: #d85353;
$activites-region: #ad7494;
$activites-regions: #b5cf40;
$hiver: #87c8ea;
$printemps: #b5cf40;


$slick-font-path: "~/../node_modules/slick-carousel/slick/fonts/" ;
$slick-loader-path: "~/../node_modules/slick-carousel/slick/" ;


// Breakpoints
$breakpoint_xs: 320px !default;
$breakpoint_sm: 576px !default;
$breakpoint_md: 768px !default;
$breakpoint_lg: 992px !default;
$breakpoint_xl: 1200px !default;