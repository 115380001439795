@media screen and (min-width: 1600px) {
  .subscriptin-email-box {
    position: fixed;
    bottom: 60px;
    right: 20px;
    z-index: 100;
    width: 35%;
  }

  .card-subscriptin-email-body {
    // height: auto;
    overflow: auto;
  }

  .split-screen-email{
    width: 65%;
    margin-left: 0;
  }
  .split-subscription-card{
    width: 58%;
  }
}

.swal-button--force{
  background-color: $danger;
  &:not([disabled]):hover{
    background-color: $danger;
  }
}

.swal-button--wait{
  background-color: $secondary;
  &:not([disabled]):hover{
    background-color: $secondary;
  }
}
