#fh-mapid{
  height: 500px;
  width:100%;
}

#fh-list-mapid{
  height: 62em;
  width:100%;
  z-index: 0;
}

.leaflet-container{
  z-index: 0;
}
.forest-house-list{
    height:47em;
}

.frame-forest-house-list{
  cursor: pointer;
}

.fh-list-search-localisation{
  width: 50% !important;
}

.fh-list-div-more-filters{
  position: absolute;
  z-index: 1;
  width: 58% !important;
  display: none;
}

.fh-list-text-more-filters{
  cursor: pointer;
}

.fh-list-div-frame-details{
  display: none;
}


.fh-list-button-close-frame-details{
  display: none;
}
