.pagination {
    .current,
    span a {
        display: block;
        padding: .5rem;
        border: 1px solid $secondary;
        margin-left: -1px;
        color: $secondary;
        min-width: 2.5rem;
        text-align: center;
    }
    .current {
        background-color: $secondary;
        color: $blanc !important;
    }
    a {
        &:hover {
            text-decoration: none;
            background-color: $dark;
            color: $blanc !important;
        }
    }
}
.page-admin{
  .pagination {
      .current,
      span a {
          // display: block;
          // padding: .5rem;
          border: 1px solid $primary;
          // margin-left: -1px;
          color: $primary;
          // min-width: 2.5rem;
          // text-align: center;
      }
      .current {
          background-color: $primary;
          color: $dark;
      }
  }
}
