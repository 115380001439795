.choose_number_days_hidden,
.max_nb_day_hidden{
  display:none;
  &.show{
    display:block;
  }
  .form-check-input {
      position: relative;
      margin-top: 0.3rem;
      margin-left: 0;
  }
  .input-group {
    max-width: 300px;
  }
}
.stayDuration{
  display: none;
}
.user_input_hidden,
.max_user_input_hidden{
  display:none;
  &.show{
    display:block;
  }
  .form-check-input {
      position: relative;
      margin-top: 0.3rem;
      margin-left: 0;
  }
  .input-group {
    max-width: 300px;
  }
}

.pricing_per_age_container{

  display:none;
  &.show{
    display:block;
  }
  a{
    cursor: pointer;
    &:hover{
      filter: brightness(85%);
    }
  }
  li{
    max-width: 500px;
  }
}


.benefit_notice{
  font-style: italic;
  opacity: 0.5;
  transition: 0.3s all ease-out;
  &:hover{
    opacity: 1;
  }
}
