.changelog-markdown {
    padding-bottom: 3em;
    width: 95%;
    h2{
        font-weight: 900;
        margin-top: 2em;
        position: sticky;
        top: -15px;
        background-color: #fff;
        padding: 1rem;
        border-bottom: #ddd 1px solid;
    }
    h3{
        padding-left: 0.5em;
    }
    h4, h5, h6{
        padding-left: 0.5em;
        color: #555;
    }
    pre, blockquote,
    p, div,
    ul, ol{
        margin-left: 2em;
    }
    code { 
        background: #F5F5F5; 
        padding: 2px 6px; 
        &:hover {
            background: rgb(255, 230, 230);
            transition: background 0.3s;
        }
    }
    pre{
        background: #F5F5F5;
        padding: 1em;
        border-radius: 5px;
        margin-top: 1em;
        margin-bottom: 1em;
        overflow-x: auto;
        code{
            background: none;
            padding: 0;
        }
    }
}

