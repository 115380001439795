
/*** Refactor ***/


/*** Index ***/

.table.message-index{
  .message_line{
    &.unread{
      *{font-weight: 700;}

    }
    &:nth-child(even){
      background-color: #f5f5f5;
    }
    td{
      vertical-align: middle;
      &.td_title{
        border-right: 0px;
      }
      &.td_badge{
        border-left: 0px;
        text-align: right;
        .fa, .far, .fas{
          font-size: 20px;
        }
      }
    }

  }
}

/*** Show ***/
h1{
  .message_action {
    button, a.btn{
      line-height: 23px;
      padding: 0px 4px;
      span{
        display: none;
      }
    }
  }
}
.message-border-response{
  border: 1px solid #c4c4c4;
  padding: 10px;
  &.ROLE_AGL{
    .message-body{
      .message-part:not(.status_pending) {
        opacity : 0.4;
      }
    }
  }
  &.opened:not(.ROLE_AGL){
    border-bottom : 0px;
  }
  .message-body {
    width: 80%;
    min-width: 240px;
    max-width: 100%;
    display: inline-block;
    .message-part{
      border-radius: 3px;
      font-size: 16px;
      margin: 0;
      padding: 5px 10px 5px 12px;
      width: 100%;

      &.received-message {
        background: lighten($noir, 95%);
        color: lighten($noir, 10%);
      }
      &.sent-message {
        background: $secondary;
        color: #fff;
      }
      &.highlight_unread{
        font-weight: 700;
        border: 2px solid $secondary;
      }
      .message-content {
        span , a.user-link{
          font-size: 11px;
          text-decoration: none;
        }
      }
    }
    .message_action {
      button, a.btn{
        line-height: 23px;
        padding: 0px 4px;
        span{
          display: none;
        }
      }
    }
  }
}

/*** NEW ***/

.message_response.container{
  &.thread{
    #message-subject,h1,a.topone
    {
      display: none;
    }
    .message-border-new{
      border-top: 0px;
    }

  }
  .message-border-new {
      border: 1px solid #c4c4c4;
      padding: 10px;
      #message-content{
        border: 0px solid ;
      }
  }

}

.type-message .input-message-write, .type-message ::after, .type-message ::before {
    box-sizing: border-box;
}

/*** end Refactor ***/



.val{
  border-radius: 50%;
  transition : all 0.3s ease-out;
  height: 46px;
  width: 46px;
  display: inline-block;
  text-align: center;
  font-size: 2rem;
  margin: 5px 0 0 0;
  &:hover{
    background: #97aa00;
    color: #ffffff !important;
    text-decoration: none;
    i.text-success{
      color: #ffffff !important;
    }
  }
}

.btn-msg-action{
  display: flex;
  align-items: center;
  cursor: pointer;
  span{
    margin: 0 5px;
  }
}
