.content-wrapper {
    color: $noir;
    padding: 1rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.tax-panel-header {
    position: relative;
    img {
        position: fixed;
        top: 50vh;
        left: 50vw;
        transform: translate(-50%, -50%);
        box-shadow: 0 0 20px rgba(0,0,0,.5);
        z-index: 10;
    }
}