.bloc_coord .card {
    position: relative;
}
.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $secondary;
    background-color: $secondary;
}
.delete-alert {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $danger;
    color: $blanc;
}

.input-group {
    .input-group-append {
        &.forced-round {
            .input-group-text {
                border-top-right-radius: .25rem;
                border-bottom-right-radius: .25rem;
            }
        }
    }
}

#email_sendAt{
  select.form-control{
    padding: 0.375rem 0.2rem;
  }
}

#lockerTemplate, #lockerTemplateRefused, #lockerTemplateFH{
  display: none;
  position: absolute;
  top: 5px;
  font-size: 16px;
  right: 20px;
  color: #444;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover{
    transform: scale(1.2);
  }
}
#selectTemplateContainer .select2-container--default.select2-container--disabled .select2-selection--single,
#selectTemplateContainerRefused .select2-container--default.select2-container--disabled .select2-selection--single,
#selectTemplateContainerFH .select2-container--default.select2-container--disabled .select2-selection--single
{
  background-color: #fff;

}
#docLoader{
  display: none;
  text-align: center;
}
.form-group {
    position: relative;
}
.modal.imageModal{
  .card{
    .card-body{
      max-height: 340px;
      overflow: scroll;
    }
  }
}

#imgLoader{
  opacity: 0;
  margin: 5px auto;
}




.chooseDraft{
  .messageChanged{
    display: none;
  }
  .changed{
    border: 1px solid #007bff;
    border-left: 10px solid #007bff;
    td, th{
      border-top: 1px solid #007bff;
      border-bottom: 1px solid #007bff;
    }
    .messageChanged{
      display: block;
    }
  }
}

#articleCoverConatainer{
  max-width: 100%;
  img{
    max-width: 100%;
  }
}

body  > .note-popover {
    display: none;
}
.note-editor.note-frame {
    border-radius: 2px;
}

.field-required {
  border: 1px solid red !important;
}

.has-error{
  border: 1px solid red !important;
  color: red;
}

.wizard-content{
  border: 0px;
}
.step-icon{
  height: 68px;
  width: 68px;
  text-align: center;
  margin: 0 auto;
  border: 2px solid #e5e5e5;
  line-height: 65px;
  border-radius: 50%;
}

.btn-primary {
    color: #fff !important;
}

.wizard{
  margin-bottom: 65px;
}

.my-w-5{
  width: 5%!important;
}
