.typehead-container {
    .list-group {
        position: absolute;
        z-index: $zindex-dropdown;
        box-shadow: 0 0 10px rgba(0,0,0,.3);
        &.hidden {
            display: none;
        }
    }
}

.suggestion-item {
    &:hover {
        cursor: pointer;
    }
}