.tooltip{
  ul{
    padding: 0px;
    text-align: left;
    list-style: none;
    margin:0;
    li{
      &::before{
        content: "⋅";
        margin: 0 4px 0 0;
      }
    }
  }
}
.tooltip-inner {
    max-width: 250px;
}

#categoryNameCounter {
  font-size: 12px;
  color: darkgrey;
}
