.error-404{
    h1{
        display: none;
    }
    .wrapper {
        background-image: url("../../public/img/page-404.jpg");
        background-position: center;
        min-height: 100vh;
    }
    .content-wrapper {
        height: calc(100vh - 50px);
    }
    div.col-8{
        background-color: #0008;
        color: $light;
        padding: 3rem 2rem 1rem;
        a{
            color: $tertiary;
            text-decoration: underline;
            &:hover{
                color: $secondary;
            }
        }
    }
}