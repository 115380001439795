ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 27px;
  width: 5px;
  height: 100%;
  z-index: 400;
}
.timeline-date {
  display: inline-block;
  margin: 25px 50px;
  z-index: 400;
  font-size: 20px;
}
.timeline-details {
  margin-top: 0px;
}
.timeline-icon {
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  margin-left: -26px;
  z-index: 400;
}

.timeline-icon > i{
  width: 20px;
  height: 20px;
  font-size: 17px;
  text-align: center;
}

.timeline-details{
  border: 0px;
}

.timeline-details-time{
  color: #999;
  float: right;
  padding: 10px;
  font-size: 12px;
}

.timeline-details-text{
  color: #999;
  font-size: 15px;
}
