html, body {
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing : antialiased;
}

body {
    .wrapper {
        height: auto;
        // height: 100vh;
        // overflow: hidden;
    }
}

.main-container {
    height: calc(100vh - 63px);
}

a {
    transition: all .2s;
    &:not([href]):not([tabindex])
    {
      cursor: pointer;
    }
}

.loading {
    opacity: .5;
}

h1 {
  color: $primary;
  border-bottom: 4px solid $primary;
  padding-bottom: 6px;
}

.dashboardBtn{
  display:block;
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left: 0;
  z-index: 1;
}

.dashboardContainer{
  width: 100%;
  justify-content: space-around;
  & > h2{
    padding: 0 15px;
  }
  .dashboardIcon{
    margin : auto;
    font-size: 2em;
    .card {
      height: 244.5px;
      width: 100%;
      margin: 0 0 1em;
      background-color: $secondary;
      color : white;
      .fas,.far{
        margin : 1em auto -10px;
      }
      .card-footer {
      	padding: 0.15rem 1.25rem 0.75rem;
      	background-color: $primary;
      	position: absolute;
      	bottom: 0;
      	right: 0;
      	left: 0;
      	z-index: 212;
      }
    }
  }
}

.comma-list > :not(:last-child)::after {
    content: ",";
}
